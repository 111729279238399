// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Contact_contactForm__0RlCK{\n    width: 90%;\n    margin: 100px auto;\n  }\n\n.Contact_form__mpPgM{\n    padding-left: 25px;\n}\n\n.Contact_form__mpPgM > *{\n    margin-top: 25px;\n}\n\n.Contact_form__mpPgM span{\n    color: red;\n    font-weight: bold;\n}\n\n@media screen and (max-width:750px){\n    .Contact_contactForm__0RlCK{\n        margin: 30px auto;\n    }\n\n    .Contact_form__mpPgM > *{\n        margin-top: 0px;\n    }\n\n    .Contact_form__mpPgM > * > *{\n        margin-top: 10px;\n    }\n\n    .Contact_form__mpPgM label{\n        font-size: var(--sm-font);\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/styles/Contact.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;EACpB;;AAEF;IACI,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,iBAAiB;AACrB;;AAEA;IACI;QACI,iBAAiB;IACrB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,gBAAgB;IACpB;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".contactForm{\n    width: 90%;\n    margin: 100px auto;\n  }\n\n.form{\n    padding-left: 25px;\n}\n\n.form > *{\n    margin-top: 25px;\n}\n\n.form span{\n    color: red;\n    font-weight: bold;\n}\n\n@media screen and (max-width:750px){\n    .contactForm{\n        margin: 30px auto;\n    }\n\n    .form > *{\n        margin-top: 0px;\n    }\n\n    .form > * > *{\n        margin-top: 10px;\n    }\n\n    .form label{\n        font-size: var(--sm-font);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contactForm": "Contact_contactForm__0RlCK",
	"form": "Contact_form__mpPgM"
};
export default ___CSS_LOADER_EXPORT___;
