// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Awi_awiHead__-0JYT{\n    width:80%;\n    margin:100px auto 100px;\n    text-align: left;\n}\n\n.Awi_awiHead__-0JYT h3{\n    font-size: 3.5rem;\n    color: #000;\n    font-weight: bold;\n}\n\np{\n    font-size: 1.2rem;\n    margin-top:20px;\n    width: 75%\n}\n\n.Awi_p1__Fr1Mx{\n    margin-top: 50px;\n    margin-bottom: 20px;\n    font-size:1.4rem ;\n    font-weight: bold;\n}\n\n@media screen and (max-width:750px){\n    .Awi_awiHead__-0JYT{\n        width: 90%;\n    }\n    .Awi_awiHead__-0JYT h3{\n        font-size: 1.8rem;\n    }\n    p{\n        width: 95%;\n        font-size: 0.875rem;\n    }\n\n    .Awi_p1__Fr1Mx{\n        font-size: 1.2rem;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/styles/Awi.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf;AACJ;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,iBAAiB;IACrB;IACA;QACI,UAAU;QACV,mBAAmB;IACvB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".awiHead{\n    width:80%;\n    margin:100px auto 100px;\n    text-align: left;\n}\n\n.awiHead h3{\n    font-size: 3.5rem;\n    color: #000;\n    font-weight: bold;\n}\n\np{\n    font-size: 1.2rem;\n    margin-top:20px;\n    width: 75%\n}\n\n.p1{\n    margin-top: 50px;\n    margin-bottom: 20px;\n    font-size:1.4rem ;\n    font-weight: bold;\n}\n\n@media screen and (max-width:750px){\n    .awiHead{\n        width: 90%;\n    }\n    .awiHead h3{\n        font-size: 1.8rem;\n    }\n    p{\n        width: 95%;\n        font-size: 0.875rem;\n    }\n\n    .p1{\n        font-size: 1.2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"awiHead": "Awi_awiHead__-0JYT",
	"p1": "Awi_p1__Fr1Mx"
};
export default ___CSS_LOADER_EXPORT___;
